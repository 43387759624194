import { isLocalEnvironment } from 'config'
import { getStorageItem, removeStorageItem } from 'utils/localStorage'
import { removeAuthCookies } from 'api/pages/api'
import { toast } from 'react-toastify'

/**
 * RemoveCookies component to handle the removal of authentication tokens
 * from local storage and cookies, and to notify the parent window.
 *
 * This function performs the following actions:
 * - If running in a local environment, removes the 'tokenAccounts' item from local storage.
 * - Removes a specific cookie by name across the '.lidarmill.com' domain.
 * - Sends a 'token-remove' message to the parent window, if it exists.
 *
 */
const RemoveCookies = () => {
  if (isLocalEnvironment()) {
    removeStorageItem('tokenAccounts')
  }
  try {
    const token = getStorageItem('tokenAccounts')
    removeAuthCookies(token)
  } catch (err) {
    toast.error(err.message)
  }
  if (window.parent) {
    window.parent.postMessage('token-remove', '*')
  }
  return null
}

export default RemoveCookies

